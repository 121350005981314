@import '../partials';

.landing-banner {
  background-color: $denim2;
  height: rem(400);
  padding: rem(100) rem(20);
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  @include bp(md){
    height: rem(480);
  }

  &__boundary {
    @include content-contaner;
    width: 100%;
  }

  &__title {
    max-width: rem(600);
    font-size: rem(32);
    line-height: rem(46);
    font-weight: $font-weight-light;
    position: relative;

    @include bp(md){
      max-width: rem(880);
      font-size: rem(66);
      line-height: rem(85);
    }
  }

  &__image {
    position: absolute;
    object-fit: cover;
    object-position: 0 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}